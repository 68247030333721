<template>
    <v-sheet>
        <!-- PC -->
        <v-sheet v-if="$vuetify.breakpoint.mdAndUp" style="padding-top:140px; padding-bottom:400px;" width="1000" class="mx-auto">
            <p class="text-center text-h4 font-weight-bold mb-10">전자금융거래 이용약관</p>
            <v-divider class="mb-8"></v-divider>
            <v-sheet :style="$vuetify.breakpoint.mdAndUp? 'width:1000px':'width:90%; margin:0 auto;'">
                <div class="rule_box3" style="padding: 30px">
                    <TermsOfElectronicFinancialTrans/>
                </div>
            </v-sheet>
        </v-sheet>
        <!-- 모바일 -->
        <v-sheet v-if="!$vuetify.breakpoint.mdAndUp" class="my-16 mx-auto">
            <p class="text-center text-h5 font-weight-bold mb-10">전자금융거래 이용약관</p>
            <v-divider class="mx-6 mb-8"></v-divider>
            <v-sheet :style="$vuetify.breakpoint.mdAndUp? 'width:1000px':'width:90%; margin:0 auto;'">
                <div class="rule_box3" style="padding: 30px">
                  <TermsOfElectronicFinancialTrans/>
                </div>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>


<script>
import TermsOfElectronicFinancialTrans from "@/views/policy/text/TermsOfElectronicFinancialTrans";

export default {
  components:{
    TermsOfElectronicFinancialTrans,
  }
}
</script>



<style>
.rule_box3{
    border: 1px solid #e9e9e9;
    padding:5px;
    background:#f9f9f9;
}

.rule_box3 h2{
    color:#a87675;
    margin-bottom:10px;
}


.rule_box3 h3{
    color:#333;
}

</style>